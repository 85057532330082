html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
}

hr {
  border: 0;
  height: 1px;
  width: 80%;
  background-image: linear-gradient(to right, #654B3E00, #654B3EFF, #654B3E00);
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.main-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-column-desktop {
  max-width: 1224px;
  width: calc(100% - 100px);
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  flex-grow: 1;
}

.content-column-tablet {
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.content-column-mobile {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.header-background {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.footer-background {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  position: relative;
}

.footer-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.footer-content {
  padding-top: 10px;
  padding-bottom: 30px;
}

.footer-content a {
  color: #F0EFF4;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-main-layout {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer-mobile-layout {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.content-background {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.menu-desktop {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0em;
  display: flex;
  flex-direction: column;
}

.menu-mobile {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0em;
  align-self: flex-end;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.menu-mobile-icon {
  color: #F0EFF4;
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5em;
}

.menu-mobile-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 100;
  background-color: #333;
  padding: 15px;
}

nav ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0em;
}

.menu-mobile-list ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0em;
  width: 100%;
}

nav ul li {
  display: flex;
  flex-direction: column;
  padding-right: 4em;
}

nav ul li.condensed {
  padding-right: 1.5em;
}

nav ul li.icon {
  padding-right: 2em;
}

nav ul li.icon.condensed {
  padding-right: 1.5em;
}

nav ul li:last-child {
  padding-right: 0em;
}

nav ul li.icon:last-child {
  padding-right: 0em;
}

nav ul li.icon.condensed:last-child {
  padding-right: 0em;
}

.menu-mobile-list ul li {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  width: 100%;
}

nav li a {
  color: #F0EFF4;
  font-family: 'PlayFair Display';
  font-weight: bold;
  font-size: 1.25em;
  text-decoration-line: none;
  padding-bottom: 5px;
}

.menu-desktop {
  filter: drop-shadow(0px 0px 16px #5a3207);
}

.menu-desktop-dsbld ul {
  box-shadow: 0px 0px 15px 5px #0001;
  background-color: #0001;
}

nav li.icon a {
  font-size: 1.75em;
}

.menu-mobile-list li a {
  font-size: 1.25em;
}

nav li a:visited {
  color: #F0EFF4;
}

nav li a:hover {
  color: #F0EFF4;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-one {
  flex: 1;
}

.hidden {
  display: none;
}


.desktop-two-col {
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: flex-start;
}

.mobile-two-col {
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  flex-direction: column;
}

.App {
  text-align: center;
}

.episode-card {
  display: flex;
  flex-direction: row;
  height: 120px;
  flex-shrink: 0;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  text-decoration-line: none;
  border: #ddd 2px solid;
  width: 100%;
  max-width: 800px;
}

.episode-card:hover {
  border: #aaa 2px solid;
}

.episode-card img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.episode-card-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.episode-title {
  font-size: 1.3em;
  font-weight: bold;
}
.episode-date {
  font-size: 1.1em;
}


.cast-pic {
  width: 100%;
  object-fit: contain;
}

.patreon-link {
  width: 100%;
  max-width: 600px;
  text-decoration: none;
}

.patreon-link button {
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-width: 1px;
  border-style: solid;
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
}

.signup-form {
  display: flex;
  flex-direction: row;
  height: 40px;
  max-width: 400px;
  align-items: center;
}

.signup-form button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  color: #1D1E18;
  background-color: #F0EFF4;
  border-radius: 12px;
  height: 100%;
  border: none;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
}

.spinner {
  border: 8px solid #ccc; /* Light grey */
  border-top: 8px solid; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.episode-ext-icons-dark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #F0EFF4;
}

.episode-ext-icons-light {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #1D1E18;
}

.link-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  opacity: 0.8;
  margin: 5px;
}

.episode-ext-icons-dark .link-button {
  border-color: #F0EFF4;
}

.episode-ext-icons-light .link-button {
  border-color: #1D1E18;
}

.episode-ext-icons-dark a {
  font-size: 1.75em;
  color: #F0EFF4;
  text-decoration: none;
}
.episode-ext-icons-light a {
  font-size: 1.75em;
  color: #1D1E18;
  text-decoration: none;
}

.episode-pic {
  width: 100%;
  object-fit: contain;
  border-radius: 20%;
}

.link-button:hover {
  opacity: 1;
}

.episode-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.episode-nav a {
  text-decoration-line: underline;
  text-align: center;
  color: initial;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.blurImg {
  filter: blur(4em) hue-rotate(0) brightness(50%);
  animation: bluranim 30s ease-in-out infinite;
}

@keyframes bluranim {
  0% { filter: blur(4em) hue-rotate(0deg) brightness(10%) }
  25% { filter: blur(8em) hue-rotate(90deg) brightness(30%) }
  50% { filter: blur(4em) hue-rotate(180deg) brightness(10%) }
  75% { filter: blur(8em) hue-rotate(270deg) brightness(30%) }
  100% { filter: blur(4em) hue-rotate(360deg) brightness(10%) }
}

.glowImg {
  filter: blur(5px) brightness(100%);
  animation: glowImgAnim 60s ease-in-out infinite;
}

@keyframes glowImgAnim {
  0% { filter: blur(5px) brightness(100%) }
  25% { filter: blur(100px) brightness(200%) }
  50% { filter: blur(5px) brightness(100%) }
  75% { filter: blur(100px) brightness(200%) }
  100% { filter: blur(5px) brightness(100%) }
}

.italic {
  font-style: italic;
}

.glow { 
  text-shadow: 5px 5px 25px #ffffff;
  animation: glowanim 60s ease-in-out infinite;
}

@keyframes glowanim {
  0% { text-shadow: 5px 5px 25px #ffffff }
  25% { text-shadow: 5px -5px 25px #ffffff }
  50% { text-shadow: -5px -5px 25px #ffffff }
  75% { text-shadow: -5px 5px 25px #ffffff }
  100% { text-shadow: 5px 5px 25px #ffffff }
}

